<template>
  <HeaderDialog
    title="Novo item"
    :show="showHide"
    :loading="loading"
    @close="close"
    @action="confirm"
    width="450"
  >
    <template v-slot:body>
      <v-container class="py-0">
        <v-form ref="form">
          <v-row justify="center">
            <v-col align="center">
              <v-text-field
                label="Nome"
                v-model="name"
                type="text"
                :rules="[requiredRule, nameRule]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col align="center">
              <v-text-field
                label="Total disponível"
                v-model="quantityAvailable"
                min="0"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col align="center">
              <v-text-field
                label="Quantidade em uso"
                v-model="quantityInUse"
                min="0"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col align="center">
              <v-text-field
                label="Preço unitário"
                prefix="R$"
                v-model="unitPrice"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </template>
  </HeaderDialog>
</template>

<script>
import { formatDateAndHour } from "@/helpers/services/utils";
import { mapGetters } from "vuex";

const defaultForm = Object.freeze({
  name: "",
  quantityAvailable: 0,
  quantityInUse: 0,
  unitPrice: 0,
});

export default {
  name: "DialogAddItem",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showHide: Boolean,
  },
  data: () => ({
    loading: false,
    ...defaultForm,
  }),
  computed: {
    ...mapGetters(["token"]),
    item() {
      const unitPrice = parseFloat(this.unitPrice) || 0;
      const quantityAvailable = parseInt(this.quantityAvailable) || 0;
      return {
        name: this.name,
        quantity_available: quantityAvailable,
        quantity_in_use: parseInt(this.quantityInUse) || 0,
        unit_price: unitPrice,
        total_price: unitPrice * quantityAvailable,
        users_apps: [],
        is_app: false,
      };
    },
  },
  methods: {
    formatDateAndHour,
    clearForm() {
      for (const key in defaultForm) {
        this[key] = defaultForm[key];
      }
    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.$emit("confirm", this.item);
        this.clearForm();
      }
    },
    close() {
      this.$emit("close", false);
      this.clearForm();
    },
    requiredRule: (value) => !!value || "Obrigatório",
    nameRule(v) {
      return (
        !this.items.find((item) => item.name == v) ||
        "Escolha um nome diferente dos items já existentes"
      );
    },
  },
};
</script>
