<template>
  <v-container fluid class="pa-0">
    <v-card
      :class="`mx-auto mb-8 mt-2`"
      :width="contentWidth"
      outlined
      max-height="auto"
    >
      <v-data-table
        :items="records"
        :headers="headers"
        :items-per-page="itemsPerPage"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': itemsOptions,
        }"
        :height="tableHeight"
        fixed-header
        multi-sort
        :sort-by="sortBy"
        item-class="statusClass"
        style="max-height: auto"
      >
        <template v-slot:top>
          <v-row dense class="my-0 py-0 mt-2">
            <v-col align="center" class="flex-grow-0 flex-shrink-1">
              <v-card-title class="my-0 word-break text-no-wrap fill-height">
                {{ pageTitle }}
              </v-card-title>
            </v-col>
            <v-col class="flex-grow-0 flex-shrink-1"></v-col>
            <v-col
              align="right"
              :class="`flex-grow-1 flex-shrink-0 mr-5 give-space ${
                $vuetify.breakpoint.xs ? 'd-flex flex-column' : ''
              }`"
            >
              <v-btn
                large
                elevation="0"
                :dark="!(loading || editing)"
                color="accent"
                @click="syncItems"
                :disabled="loading || editing"
                outlined
              >
                <v-icon
                  v-text="'mdi-sync'"
                  left
                  size="20"
                  :class="`${loading ? 'icon-spinner' : ''}`"
                />
                <span>Sincronizar</span>
              </v-btn>
              <v-btn
                v-if="editing"
                large
                elevation="0"
                :dark="loading ? false : true"
                color="accent"
                @click="cancelEdition"
                :disabled="loading"
                outlined
              >
                <v-icon v-text="'mdi-close'" left size="20" />
                <span>Cancelar</span>
              </v-btn>
              <v-btn
                v-if="editing"
                large
                elevation="0"
                :dark="loading ? false : true"
                color="accent"
                @click="saveChanges"
                :disabled="loading"
              >
                <v-icon v-text="'mdi-content-save-edit'" left size="20" />
                <span>Salvar</span>
              </v-btn>
              <v-btn
                v-if="!editing"
                large
                elevation="0"
                :dark="loading ? false : true"
                color="accent"
                @click="editItems"
                :disabled="loading"
              >
                <v-icon v-text="'mdi-pencil'" left size="20" />
                <span>Editar</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </template>
        <template v-if="editing" v-slot:body.prepend="{ headers }">
          <tr>
            <td :colspan="headers.length">
              <v-btn
                outlined
                color="accent"
                block
                @click="showDialogAddItem = true"
              >
                <v-icon v-text="'mdi-plus'" left size="20" />
                <span>Adicionar item</span>
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:footer>
          <v-divider></v-divider>
          <div class="mt-3 mb-3 ml-5">
            <span class="mr-2 font-weight-bold text--secondary"
              >Preço total:
            </span>
            <span class="font-weight-bold" style="font-size: 18px">
              {{ formatCurrency(totalPrice) }}
            </span>
          </div>
        </template>
        <template #item.statusClass="{ item }">
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" :disabled="disabled">
                <v-icon v-text="'mdi-dots-vertical'" />
              </v-btn>
            </template>
            <v-list dense class="py-0">
              <v-list-item
                :disabled="disabled"
                @click="item.deleted ? undeleteItem(item) : deleteItem(item)"
                class="text-body-2 font-weight-medium"
              >
                <v-list-item-icon class="mr-2">
                  <v-icon
                    v-text="item.deleted ? 'mdi-delete-restore' : 'mdi-delete'"
                  />
                </v-list-item-icon>
                <v-list-item-content
                  v-text="item.deleted ? 'Restaurar' : 'Excluir'"
                />
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-list-item dense class="pa-0">
            <v-list-item-icon class="mr-5" style="height: 36px">
              <Logo
                :product="logoSlug(item && item.name)"
                height="36"
                noMargins
              />
            </v-list-item-icon>
            <v-list-item-content>
              {{
                item.name && item.name.length > 40
                  ? `${item.name.slice(0, 40)}...`
                  : item.name
              }}
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:[`item.last_update`]="{ item }">
          {{
            item.last_update
              ? formatDateAndHour(item.last_update, true)
              : momentLocaleUtc(item.last_update)
          }}
        </template>
        <template v-slot:[`item.quantity_available`]="{ item }">
          <v-text-field
            dense
            hide-details
            single-line
            :disabled="disabled || item.deleted"
            v-model="item.quantity_available"
            @input="item.updateStaging(item)"
            min="0"
            type="number"
          ></v-text-field>
        </template>
        <template v-slot:[`item.quantity_in_use`]="{ item }">
          <v-text-field
            dense
            hide-details
            single-line
            :disabled="disabled || item.deleted"
            v-model="item.quantity_in_use"
            @input="item.updateStaging(item)"
            min="0"
            type="number"
            class="mr-2"
            style="display: inline-block; width: 50px"
          ></v-text-field>
        </template>
        <template v-slot:[`item.users_apps`]="{ item }">
          <v-avatar
            v-for="user_app in item.users_apps.slice(0, 3)"
            :key="user_app.user_key"
            color="primary"
            size="28"
            style="display: inline-block; margin-left: -4px"
          >
            <img
              :src="getUserByKey(user_app.user_key).photo"
              :alt="getUserByKey(user_app.user_key).name"
              referrerpolicy="strict-origin-when-cross-origin"
              style="border: solid grey 1px"
              @error="
                $event.target.src = defaultUserPhoto;
                $event.onerror = null;
              "
            />
          </v-avatar>
          <v-btn
            v-if="item.users_apps.length"
            icon
            color="white"
            width="28"
            height="28"
            :disabled="loading || item.deleted"
            class="white--text"
            :style="`
              margin-left: -4px;
              background-color: var(--v-accent-base);
              border: solid grey 1px;
              font-size: ${15 - item.users_apps.length.toString().length * 2}px
              `"
            @click="openDialogItemUsers(item)"
          >
            {{
              item.users_apps.length > 3
                ? `+${item.users_apps.length - 3}`
                : "👁"
            }}
          </v-btn>
          <span v-else>-</span>
        </template>
        <template v-slot:[`item.unit_price`]="{ item }">
          <v-text-field
            dense
            hide-details
            :prefix="currency"
            single-line
            :disabled="disabled || item.deleted"
            v-model="item.unit_price"
            @input="item.updateStaging(item)"
            type="number"
          ></v-text-field>
        </template>
        <template v-slot:[`item.total_price`]="{ item }">
          {{ formatCurrency(item.total_price) }}
        </template>
      </v-data-table>
    </v-card>

    <DialogItemUsers
      :item="selectedItemWithUsers"
      :showHide="showDialogItemUsers"
      @close="showDialogItemUsers = false"
      @itemUsers="updateItemUsers"
    />
    <DialogAddItem
      :items="records"
      :showHide="showDialogAddItem"
      @confirm="addItem"
      @close="showDialogAddItem = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { debounce } from "vuetify/lib/util/helpers";
import {
  formatDateAndHour,
  momentLocaleUtc,
  getObjectKeys,
} from "@/helpers/services/utils";
import { paging, tableHeight } from "@/helpers/variables/tablesDefault";
import { appSlugs } from "@/helpers/variables/itemsNApps";
import defaultUserPhoto from "@/assets/user-default.png";
import DialogItemUsers from "@/components/user/DialogItemUsers.vue";
import DialogAddItem from "@/components/user/DialogAddItem.vue";

export default {
  name: "Inventory",

  components: { DialogItemUsers, DialogAddItem },

  data() {
    return {
      records: [],
      pageTitle: "Inventário digital",
      tableHeight,
      currentPage: 1,
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      pageHeight: 0,
      appSlugs,
      usersByKey: {},
      defaultUserPhoto,
      showDialogItemUsers: false,
      selectedItem: null,
      editing: false,
      previousValues: {},
      staging: {},
      debounceDelayMs: 1000,
      showDialogAddItem: false,
      loadings: {},
      newRecordIndexes: [],
      currency: "R$",
      sortBy: ["name"],
    };
  },
  computed: {
    ...mapGetters(["contentWidth", "company", "token", "users"]),

    headers() {
      return [
        {
          value: "name",
          text: "Nome",
          sortable: true,
          align: "start",
          width: 250,
        },
        {
          value: "quantity_available",
          text: "Total disponível",
          sortable: true,
          align: "center",
          width: 90,
        },
        {
          value: "quantity_in_use",
          text: "Quantidade em uso",
          sortable: true,
          align: "center",
          width: 90,
        },
        {
          value: "users_apps",
          text: "Usuários",
          sortable: true,
          width: 130,
        },
        {
          value: "unit_price",
          text: "Preço unitário",
          sortable: true,
          align: "center",
          width: 100,
        },
        {
          value: "total_price",
          text: "Preço total",
          sortable: true,
          align: "left",
          width: 110,
        },
        {
          value: "last_update",
          text: "Última atualização",
          sortable: true,
          width: 170,
        },
        {
          value: "statusClass",
          text: "Ação",
          width: 48,
          align: "center",
        },
      ];
    },

    logoSlug() {
      return (app_name) => this.appSlugs[app_name] || "application";
    },

    getUserByKey() {
      return (key) => {
        const user = key in this.usersByKey ? this.usersByKey[key] : {};
        return {
          ...user,
          photo: user.photo || defaultUserPhoto,
        };
      };
    },

    selectedItemWithUsers() {
      if (this.selectedItem) {
        return {
          ...this.selectedItem,
          users: (this.selectedItem.users_apps || []).map((user_app) => ({
            ...user_app,
            ...this.getUserByKey(user_app.user_key),
          })),
        };
      } else {
        return {};
      }
    },

    disabled() {
      return !this.editing || this.loading;
    },

    loading() {
      return Object.values(this.loadings).some((loading) => loading);
    },

    totalPrice() {
      return this.records.reduce((acc, item) => acc + item.total_price, 0);
    },
  },

  watch: {
    users: {
      handler() {
        this.usersByKey = Object.fromEntries(
          this.users.map((user) => [user.key, user])
        );
      },
      immediate: true,
    },
  },

  methods: {
    ...mapMutations(["setSnackBar"]),

    getObjectKeys,
    formatDateAndHour,
    momentLocaleUtc,

    getItems() {
      this.loadings = {
        ...this.loadings,
        getItems: true,
      };

      const url = `${process.env.VUE_APP_API_BASE_URL}/company/items`;
      const headers = { Authorization: this.token };

      this.$axios
        .get(url, { headers })
        .then(({ data }) => {
          this.records = data.map((item) => ({
            ...item,
            statusClass: "d-default",
            deleted: false,
            updateStaging: debounce(this._updateStaging, this.debounceDelayMs),
          }));
        })
        .catch((err) => {
          if (err.response.status === 403) {
            this.setSnackBar({
              message: "Sem permissão de visualização",
              color: "error",
              show: true,
            });
          } else {
            this.setSnackBar({
              is_key: true,
              message_key: "unknown",
              color: "error",
              show: true,
            });
          }
          console.error("getItems()", err);
        })
        .finally(
          () =>
            (this.loadings = {
              ...this.loadings,
              getItems: false,
            })
        );
    },

    handleResize() {
      this.pageHeight = window.innerHeight;
    },

    async syncItems() {
      this.loadings = {
        ...this.loadings,
        syncItems: true,
      };

      const url = `${process.env.VUE_APP_API_BASE_URL}/company/sync-items-with-apps`;
      const headers = { Authorization: this.token };

      this.$axios
        .post(url, null, { headers })
        .then(({ data }) => {
          this.records = data.map((item) => ({
            ...item,
            statusClass: "d-default",
            deleted: false,
            updateStaging: debounce(this._updateStaging, this.debounceDelayMs),
          }));
        })
        .catch((err) => {
          this.setSnackBar({
            is_key: true,
            message_key: "unknown",
            color: "error",
            show: true,
          });
          console.error("syncItems()", err);
        })
        .finally(() => {
          this.loadings = {
            ...this.loadings,
            syncItems: false,
          };
        });
    },

    openDialogItemUsers(item) {
      this.selectedItem = item;
      this.showDialogItemUsers = true;
    },

    editItems() {
      this.sortBy = ["statusClass", "name"];
      this.editing = true;
    },
    cancelEdition() {
      for (let name in this.staging) {
        const item = this.staging[name];
        const previousItem = this.previousValues[name];
        for (let prop in previousItem) {
          item[prop] = previousItem[prop];
        }
        for (let prop in item) {
          if (!(prop in previousItem)) {
            delete item[prop];
          }
        }
      }
      for (const index of this.newRecordIndexes) {
        this.records.splice(index, 1);
      }
      this.staging = {};
      this.previousValues = {};
      this.newRecordIndexes = [];
      this.sortBy = ["name"];
      this.editing = false;
    },

    addItem(item) {
      const newItem = {
        ...item,
        statusClass: "a-created",
        deleted: false,
        updateStaging: debounce(this._updateStaging, this.debounceDelayMs),
      };
      this.records.unshift(newItem);
      this.newRecordIndexes.push(0);
      this._updateStaging(newItem);
      this.showDialogAddItem = false;
    },

    updateItemUsers(item) {
      this.selectedItem = item;
    },

    _updateStaging(item) {
      if (!(item.name in this.previousValues)) {
        this.previousValues[item.name] = { ...item };
      }
      item.unit_price = parseFloat(item.unit_price) || 0;
      item.quantity_available = parseInt(item.quantity_available) || 0;
      item.quantity_in_use = parseInt(item.quantity_in_use) || 0;
      if (item.quantity_available < item.quantity_in_use) {
        item.quantity_in_use = item.quantity_available || 0;
      }
      item.total_price = item.unit_price * item.quantity_available;
      if (!["a-created", "c-deleted"].includes(item.statusClass)) {
        item.statusClass = "b-updated";
      }
      this.staging[item.name] = item;
    },

    deleteItem(item) {
      if (!(item.name in this.previousValues)) {
        this.previousValues[item.name] = { ...item };
      }
      item.deleted = true;
      item.lastClass = item.statusClass;
      item.statusClass = "c-deleted";
      this.staging[item.name] = item;
    },
    undeleteItem(item) {
      const previousItem = this.previousValues[item.name];
      for (const prop in previousItem) {
        item[prop] = previousItem[prop];
      }
      delete item.lastClass;
      delete this.staging[item.name];
      delete this.previousValues[item.name];
    },

    saveChanges() {
      this.loadings = {
        ...this.loadings,
        saveChanges: true,
      };
      const createItems = [];
      const updateItems = [];
      const deleteItems = [];
      const removeFields = [
        "deleted",
        "statusClass",
        "lastClass",
        "last_update",
        "total_price",
        "users_apps",
        "users",
        "updateStaging",
      ];
      const staging = Object.values(this.staging).map((item) => ({ ...item }));
      for (let item of staging) {
        if (!item.key && !item.deleted) {
          createItems.push(item);
        } else if (item.key && !item.deleted) {
          updateItems.push(item);
        } else if (item.key && item.deleted) {
          deleteItems.push(item);
        } else if (!item.key && item.deleted) {
          const index = this.records.findIndex(
            (record) => record.name == item.name && !item.key && item.deleted
          );
          if (index >= 0) this.records.splice(index, 1);
        }
        for (const field of removeFields) {
          delete item[field];
        }
      }
      if (createItems.length) this.createItems(createItems);
      if (updateItems.length) this.updateItems(updateItems);
      if (deleteItems.length) this.deleteItems(deleteItems);
      this.previousValues = {};
      this.newRecordIndexes = [];
      this.sortBy = ["name"];
      this.editing = false;
      this.loadings = {
        ...this.loadings,
        saveChanges: false,
      };
    },
    createItems(items) {
      this.loadings = {
        ...this.loadings,
        createItems: true,
      };

      const url = `${process.env.VUE_APP_API_BASE_URL}/company/items`;
      const headers = { Authorization: this.token };

      this.$axios
        .put(url, { items }, { headers })
        .then(({ data }) => {
          for (let newItem of data) {
            const stagingItem = this.staging[newItem.name];
            for (const prop in newItem) {
              stagingItem[prop] = newItem[prop];
            }
            stagingItem.statusClass = "d-default";
            delete this.staging[newItem.name];
          }
        })
        .catch((err) => {
          this.setSnackBar({
            is_key: true,
            message_key: "unknown",
            color: "error",
            show: true,
          });
          console.error("createItems()", err);
        })
        .finally(() => {
          this.loadings = {
            ...this.loadings,
            createItems: false,
          };
        });
    },
    updateItems(items) {
      this.loadings = {
        ...this.loadings,
        updateItems: true,
      };

      const url = `${process.env.VUE_APP_API_BASE_URL}/company/items`;
      const headers = { Authorization: this.token };

      this.$axios
        .patch(url, { items }, { headers })
        .then(({ data }) => {
          for (let newItem of data) {
            const stagingItem = this.staging[newItem.name];
            for (const prop in newItem) {
              stagingItem[prop] = newItem[prop];
            }
            stagingItem.statusClass = "d-default";
            delete this.staging[newItem.name];
          }
        })
        .catch((err) => {
          this.setSnackBar({
            is_key: true,
            message_key: "unknown",
            color: "error",
            show: true,
          });
          console.error("updateItems()", err);
        })
        .finally(() => {
          this.loadings = {
            ...this.loadings,
            updateItems: false,
          };
        });
    },
    deleteItems(items) {
      this.loadings = {
        ...this.loadings,
        deleteItems: true,
      };

      const url = `${process.env.VUE_APP_API_BASE_URL}/company/items`;
      const headers = { Authorization: this.token };

      this.$axios
        .delete(url, { headers, data: { items } })
        .then(({ data }) => {
          const records = this.records;
          for (let item of data) {
            const index = records.findIndex((record) => record.key == item.key);
            if (index >= 0) records.splice(index, 1);
            delete this.staging[item.name];
          }
        })
        .catch((err) => {
          this.setSnackBar({
            is_key: true,
            message_key: "unknown",
            color: "error",
            show: true,
          });
          console.error("deleteItems()", err);
        })
        .finally(() => {
          this.loadings = {
            ...this.loadings,
            deleteItems: false,
          };
        });
    },

    formatCurrency(value) {
      return `${this.currency} ${(
        Math.round((value || 0) * 100) / 100
      ).toLocaleString()}`;
    },
  },
  async beforeMount() {
    await this.getItems();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style>
.give-space > * {
  margin: 4px 0px 0px 12px;
}
.a-created {
  background-color: rgba(0, 255, 0, 0.15);
}
.b-updated {
  background-color: rgba(255, 255, 0, 0.15);
}
.c-deleted {
  background-color: rgba(255, 0, 0, 0.15);
}
.d-default {
}
</style>
