<template>
  <HeaderDialog
    :title="`Usuários de ${item.name}`"
    :show="showHide"
    :loading="loading"
    @close="$emit('close', false)"
    width="750"
    readOnly
  >
    <template #body>
      <v-data-table
        id="dialog_item_users_table"
        :items="records"
        :headers="headers"
        :items-per-page="-1"
        :loading="loading"
        fixed-header
        sort-by="name"
        hide-default-footer
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-row align="center" class="spacer ma-0">
            <v-col cols="3" md="3" sm="3" class="px-0">
              <v-avatar size="32" left>
                <img v-if="!item.photo" src="@/assets/user-default.png" />
                <img v-else :src="item.photo" />
              </v-avatar>
            </v-col>
            <v-col cols="10" md="8" sm="10" class="hidden-xs-only px-0">
              {{ item.name }}
            </v-col>
          </v-row>
        </template>

        <template v-slot:[`item.has_tokens`]="{ item }">
          <div class="d-flex justify-end">
            <v-icon
              :color="item.has_tokens ? 'accent' : 'grey'"
              class="mx-2"
              v-text="
                item.has_tokens ? 'mdi-lightbulb-on' : 'mdi-lightbulb-off'
              "
              :title="
                item.has_tokens
                  ? 'Aplicação conectada'
                  : 'Aplicação foi desconectada do Google'
              "
            />
            <v-btn
              icon
              title="Sincronização individual disponível na página de usuário"
              color="grey"
            >
              <v-icon
                v-text="'mdi-sync'"
                :class="`${loading ? 'icon-spinner' : ''}`"
              />
            </v-btn>
            <v-btn
              v-if="item.has_tokens"
              icon
              title="Desconectar aplicação"
              color="accent"
              @click="removeUserApps(item)"
            >
              <v-icon v-text="'mdi-link-variant-off'" />
            </v-btn>
            <v-btn icon title="Disponível em breve" color="grey">
              <v-icon v-text="'mdi-delete'" />
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>
  </HeaderDialog>
</template>

<script>
import { formatDateAndHour } from "@/helpers/services/utils";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "DialogItemUsers",
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    showHide: Boolean,
  },
  data: () => ({
    loadings: {},
  }),
  computed: {
    ...mapGetters(["token"]),
    records() {
      return this.item.users || [];
    },
    headers() {
      return [
        {
          text: "Usuário",
          align: "start",
          filterable: true,
          value: "name",
          width: 200,
        },
        { text: "E-mail", value: "email" },
        {
          text: "Aplicação conectada",
          value: "has_tokens",
          align: "center",
          width: 20,
        },
      ];
    },
    loading() {
      return Object.values(this.loadings).some((loading) => loading);
    },
  },
  methods: {
    ...mapMutations(["setSnackBar"]),
    formatDateAndHour,
    updateItemUsers(itemUsers = {}) {
      this.$emit("itemUsers", itemUsers);
    },
    removeUserApps(user) {
      const userName = user.name;
      const appName = this.item.name;
      this.loadings = {
        ...this.loadings,
        [userName]: true,
      };
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${user.key}/applications`;
      const data = { app_names: [appName] };
      const headers = { Authorization: this.token };
      this.$axios
        .delete(url, { data, headers })
        .then(({ data }) => {
          const usersApps = [...this.item.users_apps];
          const length = usersApps.length;
          const appName = this.item.users_apps[0].tokens.display_text;
          for (let i = 0; i < length; i++) {
            const record = usersApps[i];
            if (record.user_key == user.key) {
              usersApps[i] = {
                ...record,
                ...data[appName],
              };
            }
          }
          this.updateItemUsers({
            ...this.item,
            users_apps: usersApps,
          });
          this.setSnackBar({
            show: true,
            message: `O usuário "${userName}" foi desconectado da seguinte aplicação: "${appName}".`,
          });
        })
        .catch((err) => {
          const { data } = err.response;
          console.error(data);
          const code = data.code;
          const usersApps = [...this.item.users_apps];
          const length = usersApps.length;
          for (let i = 0; i < length; i++) {
            const userApp = usersApps[i];
            if (userApp.user_key == user.key && userApp.name in data) {
              usersApps[i] = {
                ...userApp,
                error: errorMessages[code] || errorMessages["unknown"],
              };
            }
          }
          this.updateItemUsers({
            ...this.item,
            users_apps: usersApps,
          });
          this.setSnackBar({
            show: true,
            color: "error",
            message: `Não foi possível desconectar o usuário "${userName}" da aplicação "${appName}".`,
          });
        })
        .finally(() => {
          this.loadings = {
            ...this.loadings,
            [user.name]: false,
          };
        });
    },
  },
};
</script>

<style>
#dialog_item_users_table > .v-data-table__wrapper {
  overflow-y: hidden !important;
}
</style>
